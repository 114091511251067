<template>
    <before-posting>
        <v-container fluid>
            <v-row class="top-text pb-8" no-gutters align="center">
                <v-col cols="2" class="back-icon" @click="$store.commit('setStep', 1)">
                    <v-icon size="33">mdi-chevron-left</v-icon>
                </v-col>
                <slot name="title"></slot>
            </v-row>
            <v-row>
                <v-col class="pb-0" style="position: relative">
                    <autocomplete-hashtags
                        ref="story_description"
                        name="input-7-4"
                        :class="{'before-posting__text': true, block: true, 'middle-textarea-placeholder': middleTextareaText}"
                        rows="1"
                        :placeholder="$t('dialogs.write_something')"
                        :maxlength="storyDescriptionLength"
                        v-model="model.text"
                    >
                    </autocomplete-hashtags>

                </v-col>
            </v-row>
            <v-radio-group v-model="model.free" class="mt-5">
                <simple-before-posting-block
                    value="free"
                    class="price"
                    @click="onClickFree"
                >
                    <template v-slot:prepend>
                        {{ currency }}
                    </template>
                    <v-row align="center">
                        <v-col cols="7" class="pb-0 pt-0" @click="onClickPrice">
                            <v-text-field
                                color="#ffab40"
                                placeholder="0-49.99"
                                class="price-input pa-0 ma-0"
                                background-color="rgba(255,255,255,0.1)"
                                dense
                                solo
                                :disabled="isDisabledPrice"
                                hide-details
                                v-model="model.price"
                                type="number"
                                min="0"
                                v-on:input="priceValidation"
                            >

                            </v-text-field>
                        </v-col>
                        <v-col cols="5" class="text-end">
                            {{ $t('dialogs.free') }}
                        </v-col>
                    </v-row>

                </simple-before-posting-block>
            </v-radio-group>
            <span v-if="validation_errors" class="red--text ml-3">
                <strong>* </strong>
                {{ validation_errors }}
            </span>
            <v-row class="no-gutters" align="center" v-if="errors.length">
                <v-col class="no-gutters text-center" cols="12">
                    <span class="deep-orange--text">{{ $t('dialogs.something_is_missing') }}</span>
                </v-col>
            </v-row>
        </v-container>

        <v-btn class="story-post-btn mb-3"
            elevation="2"
            x-large
            :disabled="isDisabledPost" 
            :class="{'onclic': isLoading}" 
            @click="send">
            <span class="font-weight-bold" v-if="!isLoading && !isPostUploadSuccess && !isPostUploadFailed">
                {{ $t("dialogs.send") }}
            </span>
            <span v-if="isLoading" class="dot-typing"></span>
            <span class="font-weight-bold" v-if="isPostUploadSuccess">
                {{ $t("dialogs.post_upload.success") }}
            </span>
            <span class="font-weight-bold" v-if="isPostUploadFailed">
                {{ $t("dialogs.post_upload.failed") }}
            </span>
        </v-btn>

        <verify-dialog
            v-if="this.isShowVerifyDialog"
            @close="handleCloseVerifyDialog"
        />
    </before-posting>
</template>

<script>

import BeforePosting from "@/layouts/app/postingLayout/BeforePosting";
import SimpleBeforePostingBlock from "@/components/app/posting/SimpleBeforePostingBlock";
import Checkboxes from "@/components/app/posting/BeforePostingBlock";
import GradientButton from "@/components/app/button/GradientButton";
import AutocompleteHashtags from "@/components/app/form/AutocompleteHashtags";
import VerifyDialog from "@/components/app/dialogs/VerifyDialog";
import {CURRENCY_SYMBOL, MAX_STORY_DESCRIPTION_LENGTH, DEFAULT_STORY_DESTINATION} from "@/configs/constants";

import {mapActions, mapMutations, mapState} from 'vuex'

export default {
    name: "BeforePostingChat",
    data: () => ({
        errors: [],
        validation_errors: "",
        model: {
            text: '',
            destination: 'chat',
            tags: [],
            chats: [],
            subscribers: [],
            free: 'free',
            price: '',
        },
        lastDestination: DEFAULT_STORY_DESTINATION,
        isFree: '',
        tags: [],
        currency: CURRENCY_SYMBOL,
        storyDescriptionLength: MAX_STORY_DESCRIPTION_LENGTH,
        selfChats: [],
        clearChats: false,
        clearSubscribers: false,
        checkedChats: false,
        checkedSubscribers: false,
        isDisabledPost: false,
        companionId: 0,
        isLoading: false,
        isPostUploadSuccess: false,
        isPostUploadFailed: false,
    }),
    props: {
        isBroadcastChat: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        BeforePosting,
        SimpleBeforePostingBlock,
        Checkboxes,
        GradientButton,
        AutocompleteHashtags,
        VerifyDialog,
    },
    computed: {
        middleTextareaText() {
            return this.beforePostingText === ''
        },
        isDisabledPrice() {
            return !this.user.verifyOndatoAt
        },
        ...mapState({
            imageBlob: state => state.story.imageBlob,
            videoDataUrl: state => state.story.videoDataUrl,
            videoDuration: state => state.story.videoDuration,
            videoBlob: state => state.story.videoBlob,
            fileResolution: state => state.story.fileResolution,
            user: state => state.user.user.data,
            type: state => state.story.type,
            isMuted: state => state.story.isMuted,
            overlayDataUrl: state => state.story.overlayDataUrl,
            subscribers: state => state.user.user.subscribers,
            fromChat: state => state.story.isShowCameraScreen.fromChat,
            isShowVerifyDialog: state => state.user.components.isShowVerifyDialog,
        }),
    },
    watch: {
        'model.price'(value) {
            value = Number.parseFloat(value)
            value = isNaN(value) ? 0 : value
            if (this.model.free === 'free' && value) {
                this.model.free = ''
            }

            if (this.model.free === '' && (value === 0)) {
                this.model.free = 'free'
            }
        },
        'model.free'(value) {
            if (this.model.price && value === 'free') {
                this.model.price = null
            }
        },

    },
    methods: {
        ...mapMutations([
            'showSnackBar',
            'hideVerifyDialog',
            'setStep'
        ]),
        async send() {
            if (this.model.price > 0) {
                const verify = await this.isVerifyUser()
                if (!verify) {
                    return
                }
            }
            let file = this.type === 'photo' ? this.imageBlob : this.videoBlob

            this.model.destination = this.isBroadcastChat ? 'broadcast' : 'chat'

            let data = this.model;

            // data.tags = this.buildData(data.tags)
            // data.chat = this.buildData(data.chat)
            // data.subscribers = this.buildData(data.subscribers)

            this.errors = [];
            this.isDisabledPost = true;
            this.isLoading = true;
            this.isPostUploadSuccess = false;
            this.isPostUploadFailed = false;

            this.$store.dispatch('sendStory', {
                model: data,
                file: file,
                muted: this.isMuted,
                height: this.fileResolution.height,
                width: this.fileResolution.width,
                overlayDataUrl: this.overlayDataUrl,
                videoDuration: this.videoDuration,
                isFrontCamera: this.$core.storyCore.isFrontCamera,
                isLandscape: this.$core.storyCore.isLandscape,
                type: this.$store.state.story.type,
            })
                .then(() => {
                    this.$auth.fetch()

                    let chatStoryMessageInfo = {
                        message_type: 'Story',
                        channel: this.isBroadcastChat ? 'Broadcast' : 'Direct Message',
                    };
                    // TODO: navigate to the exact chat
                    if (this.isBroadcastChat) {
                        chatStoryMessageInfo.ownBroadcast = true;
                        this.$router.push({name: 'user.own.channels'})
                    } else {
                        chatStoryMessageInfo.to = this.fromChat.companion.name;
                        this.$router.push({name: 'user.own.messages'})
                    }

                    if (this.$route.name === 'user.own.profile.view') {
                        this.getUserStories()
                    }
                    this.$mixpanel.click('Send Story', 'Chat Screen', {
                        message_info: chatStoryMessageInfo
                    });
                    this.isDisabledPost = false
                    this.isLoading = false
                    this.isPostUploadSuccess = true
                    setTimeout(() => {
                        this.setStep(-1);
                    }, 2000);
                })
                .catch((e) => {
                    this.isPostUploadFailed = true;
                    if (typeof e.response.data.err_msg !== 'undefined' && e.response.data.err_msg.length > 0) {
                        this.errors.push(e.response.data.err_msg)
                        this.showSnackBar(e.response.data.err_msg[0])
                        console.log(e.response.data.err_msg[0])
                    }
                    this.isDisabledPost = false
                    this.isLoading = false
                })
        },
        buildData(obj) {
            if (Array.isArray(obj)) {
                if (typeof obj[0] !== 'object') {
                    return obj.join(',')
                } else {
                    let buildedData = [];
                    for (let i in obj) {
                        buildedData.push(obj[i]['id'])
                    }
                    return buildedData.join(',')
                }
            }else{
                return obj;
            }
        },
        handleCloseVerifyDialog() {
            this.hideVerifyDialog()
        },
        onClickFree(value) {
            if (this.isFree === value) {
                this.isFree = ''
                this.model.free = ''
            } else {
                this.isFree = value
            }
        },
        async onClickPrice() {
            if (this.isDisabledPrice) {
                await this.isVerifyUser()
            }
        },
        priceValidation() {
            if (this.model.price > 49.99) {
                this.isDisabledPost = true;
                this.validation_errors = this.$t('dialogs.max_price_error', { min_dm_price: 0, max_dm_price: 49.99});
            } else {
                this.isDisabledPost = false;
                this.validation_errors = '';
            }
        },

        ...mapActions([
            'getTags',
            'setMessages',
            'getUserStories',
            'isVerifyUser',
            'getOwnSubscribers',
        ])
    },
    async mounted() {
        this.getTags().then(res => this.tags = res)
        this.getOwnSubscribers()
        let messages = await this.setMessages()
        this.selfChats = messages.data
        if (this.fromChat.chatId) {
            this.companionId = this.fromChat.chatId
            this.model.chats.push(this.fromChat.chatId)
        } else {
            this.selfChats.unshift({
                id: {
                    action: 'new',
                    user_id: this.fromChat.companion.id,
                },
                image: this.fromChat.companion.avatar_url,
                user_id: this.fromChat.companion.id,
                name: this.fromChat.companion.name,
            })
        }
    }
}

</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables';

.title-block__before-posting {
    font-size: 1.1em;
}

.mdi-checkbox-marked {
    color: blue
}

.block {
    background-color: #1A1A1A;
    border-radius: 16px;
    outline: none;
}

.before-posting__text {
    font-size: 15px;
    color: white;
    width: 100%;
    height: 70px;
    outline: none;
    resize: none;
    border: solid 1px #707070;
    //margin-top: 1.6em;
    padding: 10px 10px 10px 1.1em;

}

.middle-textarea-placeholder {
    line-height: 46px;
}

.post-destination {
    border-radius: 16px;
    font-size: 15px;
    background-color: #1A1A1A;
    color: white;
    width: 100%;
    padding: 0 1em;
    min-height: 60px;
    display: flex;
}

.v-messages {
    display: none;
    min-height: 0;
}

.gradient-checkbox {
    background: -webkit-linear-gradient(#eee, #333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.linear-gradient--text {
    color: red;
    font-size: 12px
}

.top-text {
    font-size: 1.7em;
    font-weight: 900;
}

// Animation loader CSS
button {
    outline:none;
    height: 50px !important;
    text-align: center;
    border-radius:40px;
    background: $accent-background;
    letter-spacing:1px;
    text-shadow: none;
    cursor: pointer;
    transition: all 0.25s ease;
    width: 80%;
    margin: 0 auto;
}

.onclic {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 12px 5px;
    width: 50px;
    min-width: 50px !important;
    &:before, &:after { 
        display: none;
     }
}

.dot-typing {
    position: relative;
    left: -9999px;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: #fff;
    color: #fff;
    box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0 0 0 #fff;
    animation: dot-typing 2s infinite linear;
}
 
@keyframes dot-typing {
    0% {
        box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0 0 0 #fff;
    }
    8.33% {
        box-shadow: 9984px 0px 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0 0 0 #fff;
    }
    16.667% {
        box-shadow: 9984px 0px 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0 0 0 #fff;
    }
    24.99% {
        box-shadow: 9984px -6px 0 0 #fff, 9999px 0px 0 0 #fff, 10014px 0 0 0 #fff;
    }
    33.33% {
        box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0 0 0 #fff;
    }
    41.66% {
        box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0px 0 0 #fff;
    }
    49.987% {
        box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0 0 0 #fff;
    }
    58.317% {
        box-shadow: 9984px 0 0 0 #fff, 9999px -6px 0 0 #fff, 10014px 0 0 0 #fff;
    }
    66.66% {
        box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0 0 0 #fff;
    }
    74.97% {
        box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0 0 0 #fff;
    }
    83.30% {
        box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0 0 0 #fff;
    }
    91.637% {
        box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 0 #fff, 10014px -6px 0 0 #fff;
    }
    100% {
        box-shadow: 9984px 0 0 0 #fff, 9999px 0 0 0 #fff, 10014px 0 0 0 #fff;
    }
}
// END Animation loader CSS

</style>
