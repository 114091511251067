<template>
    <div>
        <v-container class="px-2 pb-2 mt-6">
            <v-row class="mt-n7" no-gutters justify="space-between">
                <v-col cols="auto">
                    <v-btn icon>
                        <app-icon
                            :active="activeFilter"
                            icon="filter"
                            :clickable="true"
                            @click="filterBtnClick"
                        />
                    </v-btn>
                </v-col>

                <v-img
                    class="mx-auto"
                    width="154"
                    style="max-width: 154px"
                    src="@/assets/images/logo/noodzly-logo.png"
                />

                <v-col cols="auto">
                    <v-btn icon>
                        <router-link :to="{ name: 'user.own.profile.wallet' }">
                            <app-icon :clickable="true" icon="wallet" />
                        </router-link>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>

        <!--        <home-swiper :title="$t('pages.app.home.subscribed')" :items="subscribeds" v-if="!!subscribeds && Object.values(subscribeds).length" @click="showStory"/>-->
        <home-swiper
            class="mb-2"
            :title="$t('pages.app.home.subscribed')"
            :items="subscriptions"
            v-if="!!subscriptions && Object.values(subscriptions).length"
            @click="showStory"
        />
        <home-swiper
            class="mb-2"
            :title="$t('pages.app.home.following')"
            :items="filteredFollowing"
            v-if="
                !!filteredFollowing && Object.values(filteredFollowing).length
            "
            @click="showStory"
        />
        <home-discover
            class="mb-2"
            :title="$t('pages.app.home.discover')"
            :items="filteredDiscovers"
            v-if="
                !!filteredDiscovers && Object.values(filteredDiscovers).length
            "
            @click="showStory"
        />
        <app-transition>
            <filter-home
                v-if="isShowFilters"
                @close="isShowFilters = false"
                @reset="resetFilters"
                @save="setFilters"
            ></filter-home>
        </app-transition>
            
        <story-view
            v-if="isStoryShow"
            @close="handleCloseStory"
            @prevUserStory="handlePrevUserStory"
            @nextUserStory="handleNextUserStory"
            @reload-story="handleReloadStory"
        />
        <Observer @intersect="loadMore" />
    </div>
</template>

<script>
import HomeSwiper from "@/components/app/home/HomeSwiper";
import HomeDiscover from "@/components/app/home/HomeDiscover";
import FilterHome from "@/components/app/home/FilterHome";
import StoryView from "@/views/app/story/StoryView";
import { mapActions, mapState } from "vuex";
import Observer from "@/components/app/Observer";

export default {
    name: "Home",
    components: {
        FilterHome,
        HomeSwiper,
        HomeDiscover,
        StoryView,
        Observer
    },
    data() {
        return {
            isShowFilters: false,
            isStoryShow: false,
            activeFilter: false,
            filters: {
                sections: ["subscribed", "following", "discover"],
                range: [18, 100],
                panel: "",
                tags: [],
                activeGenders: [],
            },
            postingStep: this.$store.state.story.isShowCameraScreen.step,
            usersStories: [],
            prevIndexUsersStories: 0,
            currentIndexUsersStories: 0,
            nextIndexUsersStories: 0,
            page: 1,
            perPageLimit: 0,
            estimatedRecordHeight: 0,
            timer: null
        };
    },
    computed: {
        ...mapState({
            subscriptions: (state) => state.user.user.subscriptions,
            following: (state) => state.user.user.following,
            discovers: (state) => state.user.user.discovers,
            isStoryData: (state) => state.user.user.isStoryData,
            currenUser: (state) => state.user.user.data,
        }),
        filteredFollowing() {
            if (this.subscriptions?.length) {
                const subscriptionIds = new Set(
                    this.subscriptions?.map((sub) => sub.id)
                );
                return this.following?.filter(
                    (item) => !subscriptionIds?.has(item.id) && item.has_stories !== 0
                );
            }
            return this.following?.filter((item) => item.has_stories !== 0);
        },
        filteredDiscovers() {
            if (this.subscriptions?.length && this.discovers?.length) {
                const subscriptionIds = new Set(
                    this.subscriptions?.map((sub) => sub.id)
                );
                return this.discovers?.filter(
                    (item) => !subscriptionIds?.has(item.id)
                );
            }
            return this.discovers;
        },
    },
    methods: {
        ...mapActions([
            "getStory",
            "getOwnSubscriptions",
            "getOwnFollowers",
            "getOwnFollowing",
            "getOwnDiscovers",
        ]),

        showStory(model) {
            this.usersStories = model.items;
            this.currentIndexUsersStories = model.index;
            this.setNeighborUserIndex();
            this.loadStory();
        },
        async loadMore() {
            if (this.isStoryShow) return;
            if (this.isStoryData && this.page > 1) {
                await this.getOwnDiscovers({ ...this.filters, isExistStory: 1, page: this.page, limit: this.perPageLimit });
                this.page++;
            }
        },

        async setFilters(e) {
            this.filters = e;
            this.page = 1;
            await this.loadData();
            this.activeFilter = true;
            this.isShowFilters = false;
        },
        async resetFilters(e) {
            this.filters = e;
            this.page = 1;
            await this.loadData();
            this.activeFilter = false;
            this.isShowFilters = false;
        },
        async loadData() {
            await this.getOwnSubscriptions({ ...this.filters });
            await this.getOwnFollowing({ ...this.filters });
            await this.getOwnDiscovers({ ...this.filters, isExistStory: 1, page: this.page, limit: this.perPageLimit });
            this.page++;
        },
        setNeighborUserIndex() {
            this.prevIndexUsersStories =
                this.currentIndexUsersStories === 0
                    ? null
                    : this.currentIndexUsersStories - 1;
            this.nextIndexUsersStories =
                this.currentIndexUsersStories === this.usersStories.length - 1
                    ? null
                    : this.currentIndexUsersStories + 1;
        },
        handlePrevUserStory() {
            if (this.prevIndexUsersStories === null) {
                return this.handleCloseStory();
            }
            this.currentIndexUsersStories = this.prevIndexUsersStories;
            this.setNeighborUserIndex();
            this.loadStory();
        },
        handleNextUserStory() {
            if (this.nextIndexUsersStories === null) {
                return this.handleCloseStory();
            }
            this.currentIndexUsersStories = this.nextIndexUsersStories;
            this.setNeighborUserIndex();
            this.loadStory();
        },
        handleCloseStory() {
            this.page = 1;
            this.loadData();
            this.isStoryShow = false;
        },
        async loadStory() {
            const id =
                this.usersStories[this.currentIndexUsersStories].id || null;
            if (id) {
                this.getStory({ id }).then(() => {
                    this.isStoryShow = true;
                });
            }
        },
        handleReloadStory() {
            this.loadStory();
        },
        async refreshDiscovery() {
            this.page = 1;
            await this.getOwnDiscovers({ ...this.filters, isExistStory: 1, page: this.page, limit: this.perPageLimit });
            this.page++;
        },

        filterBtnClick() {
            this.isShowFilters = true;

            this.$mixpanel.identifyUser(this.currenUser.email);
            this.$mixpanel.click('Filter', 'Home Screen');
        },
    },
    destroyed() {
        clearInterval(this.timer);
    },
    async mounted() {
        const homeFilters = localStorage.getItem("home_filters");
        if (homeFilters) {
            this.activeFilter = true;
            this.filters = JSON.parse(homeFilters);
        }
        this.estimatedRecordHeight = 50;
        this.perPageLimit = Math.ceil(Math.floor(window.innerHeight / this.estimatedRecordHeight) / 3) * 3;
        await this.loadData();
        this.timer = setInterval(this.refreshDiscovery, 180000);
    },
};
</script>
