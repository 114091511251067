<template>
    <setting-layout
        style="margin-bottom: 74px;"
        :handle-back-by-emit="!!companionId"
        :title="
            isBroadcastingChannel && isChatOwner
                ? $t('pages.channels.yourChannel')
                : ''
        "
        :back="isBroadcastingChannel ? { name: 'user.own.channels' } : { name: 'user.own.messages' }"
        @back="$emit('back')"
        
    >
        <v-container fluid class="pt-0 pl-5 pr-5">
            <div
                v-if="
                    !isBroadcastingChannel ||
                    (isBroadcastingChannel && !isChatOwner)
                "
                class="mb-15"
            >
                <v-row v-if="companion.id" justify="center" no-gutters>
                    <profile-image-disabled-story-view
                        size="100"
                        border="4"
                        :font-size="31"
                        :has-not-watched-stories="
                            companion.hasNotWatchedStories
                        "
                        :is-exist-story="companion.isExistStory"
                        :img="companion.profileImage"
                        :username="companion.name"
                        :id="companion.id"
                        @click="
                            !companion.isExistStory
                                ? $router.push({
                                      name: 'otherUser.profile.viewSlug',
                                      params: { slug: companion.slug, from: $route.name, id: $route.params.id },
                                  })
                                : ''
                        "
                    />
                </v-row>
                <v-row justify="center" align="center">
                    <icon-signal v-if="isBroadcastingChannel" class="mr-2" />
                    <router-link
                        :to="{
                            name: 'otherUser.profile.viewSlug',
                            params: { slug: companion.slug, from: $route.name, id: $route.params.id },
                        }"
                        class="text-h6 font-weight-regular"
                    >
                        {{ companion.name }}
                    </router-link>
                    <verified-icon
                        v-if="companion.verified"
                        :height="16" :width="16" class="ml-1" />
                </v-row>
            </div>

            <div
                v-if="isBroadcastingChannel && isChatOwner"
                class="grey--text ml-12 text-body-2 mt-4"
            >
                {{ $t("pages.channels.description.forCreators") }}
            </div>

            <div v-if="isBroadcastingChannel && pinnedMessage">
                <div class="show-pinned-chat-message">
                    <span>{{ $t("pages.channels.pinned_message") }}</span>
                    <span @click="scrollToPinnedMessage = true">
                        {{ pinnedMessageDisplay }}
                    </span>
                    <v-icon @click="messageUnpinned">mdi-close</v-icon>
                </div> 
            </div>
            <message-item
                class="mt-4"
                v-for="(message, i) in messages"
                :key="i"
                :message="message"
                :last-message="i === messages.length - 1"
                :scroll-to-last-message="scrollToLastMessage"
                :scroll-to-pinned-message="scrollToPinnedMessage"
                :pinned-message="pinnedMessage"
                @scrolled="handleScrolled"
                @scrolled-to-pin="scrolledToPin"
                @reload-chat="handleReloadChat"
                :is-broadcast-chat="isBroadcastingChannel"
                :is-broadcast-owner="isChatOwner"
                @message-pinned="MessagePinned"
            />
            <typing-message
                class="mt-4"
                :sender-img="companion.profileImage"
                :is-typing="isTyping"
            />
        </v-container>
        <div
            v-touch="{ up: () => openTipMenu() }"
            @touchmove.stop.prevent
            class="bottom-control"
        >
            <div class="recording-deleted" v-if="isRecordingDeleted">
        		<img :src="require('@/assets/delete_recording.gif')" alt="Loading..." />
        	</div>

            <button v-if="!isBroadcastingChannel || (isBroadcastingChannel && !isChatOwner)">
                <app-icon
                    icon="dollar"
                    size="24"
                    color="rgba(255,255,255,0.4)"
                    @click="openTipMenu"
                />
            </button>

            <div style="width: 100%">
                <text-field
                    :placeholder="$t('dialogs.your_message')"
                    class="pa-1"
                    dense
                    style="border-radius: 60px"
                    v-model="messageModel"
                    @keyup.enter="sendMessageMethod"
                    @keydown="handleTypingMessage"
                />
            </div>

            <template v-if="!isBroadcastingChannel || (isBroadcastingChannel && isChatOwner)">
                <button v-if="messageModel.length === 0" class="mr-2">
                    <app-icon
                        @click="handleClickPhoto"
                        icon="photo"
                        size="18"
                        color="rgba(255,255,255,0.4)"
                    />
                </button>

                <!-- TODO: Fix microphone-->
                <audio-recorder
                    class="mr-5"
                    v-if="messageModel.length === 0"
                    @startRecordingAudio="startRecordingAudio"
                    @stopRecordingAudio="stopRecordingAudio"
                    @cancelRecordingAudio="cancelRecordingAudio"
                />
            </template>

            <gradient-button
                v-if="messageModel.length !== 0"
                fab
                class="ma-0 pa-0"
                :has-max-height="false"
                width="40"
                height="40"
                :loading="isLoading"
                @click="sendMessageMethod"
            >
                <send-button-icon :height="30" :width="30" />
            </gradient-button>
        </div>
        <tip-menu-dialog
            @close="closeTipMenu"
            v-if="isShowTip"
            :target-verified="verified"
            :target-username="companion.name"
            @save="sendMessageTipMethod"
            :loading="$store.getters.loadingBtn(['setting', 'sendBtnMsg'])"
        />
        <insufficient-funds-dialog
            v-if="insufficientFundsError"
            @close="handleCloseInsufficientFundsError"
        />
        <direct-message-dialog
            v-if="showDirectMessageDialog"
            :subscribe_price="subscribePrice"
            :direct_price="directPrice"
            :paid_options="paidOptions"
            @subscribe="handleSubscribe"
            @direct-message="handleDirectMessage"
            @close="handleCloseDirectMessageDialog"
        />
        <transition-group
            appear
            enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOut"
            duration="200"
        >
            <record-screen
                v-if="step === 0"
                key="screen"
                :is-front-camera="isFrontCamera"
                @change-camera="isFrontCamera = $event"
            />
            <view-result
                v-if="step === 1"
                key="result"
                @close="handleCloseResult()"
            />
            <before-posting-chat v-if="step === 2" key="posting" :is-broadcast-chat="isBroadcastingChannel ? true : false" />
        </transition-group>
    </setting-layout>
</template>

<script>
const TYPE_MESSAGE = 'message';
const TYPE_STORY = 'story';
const TYPE_USER_TIP = 'user_tip';
const TYPE_CREATOR_TIP = 'creator_tip';
const TYPE_AUDIO = 'audio';

import SettingLayout from "@/layouts/app/profile/SettingLayout";
import ProfileImage from "@/components/app/profile/ProfileImage";
import MessageItem from "@/components/app/chat/MessageItem";
import TypingMessage from "@/components/app/chat/TypingMessage";
import TextField from "@/components/app/form/TextField";
import GradientButton from "@/components/app/button/GradientButton";
import SwipeTipMenu from "@/components/app/profile/SwipeTipMenu";
import InsufficientFundsDialog from "@/components/app/dialogs/InsufficientFundsDialog";
import RecordScreen from "@/views/app/postingView/RecordScreen";
import ViewResult from "@/views/app/postingView/ViewResultScreen";
import BeforePostingChat from "@/views/app/postingView/BeforePostingChat";
import DirectMessageDialog from "@/components/app/dialogs/DirectMessageDialog";
import TipMenuDialog from "@/components/app/dialogs/TipMenuDialog";

import { mapActions, mapMutations, mapState } from "vuex";

import { ERROR_KEYS, INSUFFICIENT_FUNDS_ERROR } from "@/configs/constants";
import { requestCore } from "@/core";
import ProfileImageDisabledStoryView from "@/components/app/profile/ProfileImageDisabledStoryView.vue";
import IconSignal from "@/assets/images/icons/signal.svg?inline";
import VerifiedIcon from "@/components/app/common/VerifiedIcon";
import SendButtonIcon from "@/components/app/common/SendButtonIcon";
import AudioRecorder from "@/components/app/common/AudioRecorder";

export default {
    name: "Chat",
    data: () => ({
        storyExist: true,
        userImg:
            "https://i.pinimg.com/originals/97/e4/2a/97e42a82fc7911961d3ca55f54d1372c.jpg",
        messages: [],
        messageModel: "",
        isBroadcastingChannel: null,
        isChatOwner: null,
        isShowTip: false,
        interlocutorId: null,
        insufficientFundsError: false,
        chatChannel: false,
        isTyping: false,
        typingTimer: false,
        scrollToLastMessage: false,
        showDirectMessageDialog: false,
        companion: false,
        paidOptions: [],
        isFrontCamera: false,
        isAudioRecording: false,
        mediaRecorder: null,
        audioChunks: [],
        isRecordingDeleted: false,
        chatId: null,
        pinnedMessage: null,
        scrollToPinnedMessage: false,
        isLoading: false,
    }),
    watch: {
        isTyping(value) {
            if (value === true) {
                this.scrollToLastMessage = true;
            }
        },
    },
    components: {
        InsufficientFundsDialog,
        SwipeTipMenu,
        GradientButton,
        TextField,
        MessageItem,
        ProfileImage,
        ProfileImageDisabledStoryView,
        SettingLayout,
        TypingMessage,
        DirectMessageDialog,
        RecordScreen,
        ViewResult,
        BeforePostingChat,
        IconSignal,
        VerifiedIcon,
        TipMenuDialog,
        SendButtonIcon,
        AudioRecorder,
    },
    props: {
        companionId: { type: Number },
    },
    methods: {
        ...mapMutations(["showSnackBar", "setStep"]),
        async sendMessageMethod() {
            try {
                this.isLoading = true;
                if (this.isBroadcastingChannel) {
                    await axios
                        .post(
                            `/api/messages/send/channel/${this.$route.params.chat_id}`,
                            {
                                message: this.messageModel,
                            }
                        )
                        .then((response) => {
                            if (typeof response.data !== "undefined") {
                                this.setMessages(response.data.data);
                                this.messageModel = "";

                                if (!this.chatChannel) {
                                    this.setChatChannel();
                                }
                                this.toLastMessage();
                                this.isLoading = false;

                                const broadcastMessageInfo = {
                                    message_type: 'Text',
                                    channel: 'Broadcast',
                                    ownBroadcast: this.isChatOwner ? true : false,
                                };
                                this.$mixpanel.click('Send Text', 'Chat Screen', {
                                    message_info: broadcastMessageInfo
                                });
                            }
                        })
                        .catch(({ response }) => {
                            this.showSnackBar(
                                response.data.err_msg || response.data.message
                            );
                            this.isLoading = false;
                        });
                    return;
                }

                await this.checkPermissionToSendMessages();

                await this.sendMessage({
                    user_id: this.interlocutorId,
                    message: this.messageModel,
                })
                    .then((res) => {
                        if (typeof res.data !== "undefined") {
                            this.setMessages(res.data);
                            this.messageModel = "";

                            if (!this.chatChannel) {
                                this.setChatChannel();
                            }
                            this.toLastMessage();
                            this.isLoading = false;

                            const chatMessageInfo = {
                                message_type: 'Text',
                                channel: 'Direct Message',
                                to: this.companion.name
                            };
                            this.$mixpanel.click('Send Text', 'Chat Screen', {
                                message_info: chatMessageInfo
                            });
                        }
                    })
                    .catch(({ response }) => {
                        const { data, status } = response;
                        if (status === 400) {
                            if (response.data.err_key === "should_pay_option") {
                                this.showDirectMessageDialog = true;
                                this.paidOptions = Array.isArray(
                                    data.err_paid_options
                                )
                                    ? data.err_paid_options
                                    : [];
                                return;
                            }
                            if (response.data.err_key === "cannot_send_msg") {
                                this.showSnackBar(response.data.err_msg);
                                return;
                            }
                        }
                        this.showSnackBar(
                            this.$t("messages.something_went_wrong")
                        );
                        this.isLoading = false;
                    });
            } catch (error) {
                console.log(error);
            }
        },
        async checkPermissionToSendMessages() {
            try {
                await requestCore
                    .POST(
                        "messages/check-permission-to-send",
                        { user_id: this.interlocutorId },
                        "/api/messages/check-permission-to-send"
                    )
                    .catch(({ response }) => {
                        const { data, status } = response;
                        if (status === 400) {
                            if (response.data.err_key === "should_pay_option") {
                                this.showDirectMessageDialog = true;
                                this.paidOptions = Array.isArray(
                                    data.err_paid_options
                                )
                                    ? data.err_paid_options
                                    : [];
                            }
                            if (response.data.err_key === "cannot_send_msg") {
                                this.showSnackBar(response.data.err_msg);
                            }

                            throw response.data.err_msg;
                        }
                        this.showSnackBar(
                            this.$t("messages.something_went_wrong")
                        );
                        throw response.data.err_msg;
                    });
            } catch (error) {
                throw error;
            }
        },
        closeTipMenu() {
            this.isShowTip = false;
        },
        openTipMenu() {
            this.isShowTip = true;
        },
        async sendMessageTipMethod(data) {
            data.message = data.text;
            if (this.isBroadcastingChannel && !this.isChatOwner) {
                await this.sendTipMessageToBroadcast({
                    chat_id: this.$route.params.chat_id,
                    message: this.messageModel,
                    ...data,
                })
                .then((res) => {
                    this.setMessages(res.data);
                    this.messageModel = "";
                    if (!this.chatChannel) {
                        this.setChatChannel();
                    }

                    const broadcastTipMessageInfo = {
                        message_type: 'Tip',
                        channel: 'Broadcast',
                        ownBroadcast: false,
                    };
                    this.$mixpanel.click('Send Tip', 'Chat Screen', {
                        message_info: broadcastTipMessageInfo
                    });
                })
                .catch((e) => {
                    if (e.response.data.err_key === INSUFFICIENT_FUNDS_ERROR) {
                        this.insufficientFundsError = true;
                    }
                });
            } else {
                await this.sendTipMessage({
                    user_id: this.interlocutorId,
                    message: this.messageModel,
                    ...data,
                })
                .then((res) => {
                    this.setMessages(res.data);
                    this.messageModel = "";
                    if (!this.chatChannel) {
                        this.setChatChannel();
                    }

                    const chatTipMessageInfo = {
                        message_type: 'Tip',
                        channel: 'Direct Message',
                        to: this.companion.name
                    };
                    this.$mixpanel.click('Send Tip', 'Chat Screen', {
                        message_info: chatTipMessageInfo
                    });
                })
                .catch((e) => {
                    if (e.response.data.err_key === INSUFFICIENT_FUNDS_ERROR) {
                        this.insufficientFundsError = true;
                    }
                });
            }
            this.messageModel = "";
        },
        handleCloseInsufficientFundsError() {
            this.insufficientFundsError = false;
        },
        handleTypingMessage() {
            if (this.chatChannel) {
                this.chatChannel.whisper("TypingMessageEvent", {
                    typing: true,
                });
            }
        },
        handleScrolled() {
            this.readMessages({
                chat_id: this.chatId,
            });
            this.scrollToLastMessage = false;
        },
        scrolledToPin() {
            this.scrollToPinnedMessage = false;
        },
        setMessages(data) {
            this.messages.push(data);
            this.toLastMessage();
        },
        handleShowTypingMessage() {
            this.isTyping = true;
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => {
                this.isTyping = false;
            }, 2000);
        },
        handleIncomingMessage(message) {
            if (
                this.isBroadcastingChannel ||
                message.user_id !== this.$auth.user().id
            ) {
                this.setMessages(message);
            }
        },
        async handleSubscribe() {
            this.showDirectMessageDialog = false;
            await this.subscribe({ id: this.interlocutorId }).then((res) => {
                if (typeof res.redirectUrl !== "undefined") {
                    this.$window.location = res.redirectUrl;
                }
                if (typeof res.success !== "undefined") {
                    this.$store.commit("showSnackBar", res.success);
                }
            });
            await this.setOtherUser(this.interlocutorId);
        },
        handleCloseDirectMessageDialog() {
            this.showDirectMessageDialog = false;
        },
        handleCloseResult() {
            this.setStep(0);
        },
        async handleDirectMessage() {
            await this.buyDirectMessage(this.interlocutorId)
                .then((res) => {
                    if (typeof res.redirectUrl !== "undefined") {
                        this.$window.location = res.redirectUrl;
                    }
                    if (typeof res.success !== "undefined") {
                        this.$store.commit("showSnackBar", res.success);
                    }
                })
                .catch(({ response }) => {
                    if (response.status === 400) {
                        if (
                            response.data.err_key === INSUFFICIENT_FUNDS_ERROR
                        ) {
                            this.insufficientFundsError = true;
                            this.showDirectMessageDialog = false;
                            return;
                        }
                    }
                    this.showSnackBar(this.$t("messages.something_went_wrong"));
                });
            await this.setOtherUser(this.interlocutorId);
            this.handleCloseDirectMessageDialog();
        },
        async handleClickPhoto() {
            try {
                // Add photo story feature to broadcast
                if (this.isBroadcastingChannel && this.isChatOwner) {
                    const fromChat = {
                        companion: this.companion,
                        chatId: this.chatId,
                    };
                    this.setStepFromChat({ step: 0, fromChat: fromChat });
                    return;
                }

                await this.checkPermissionToSendMessages();

                const fromChat = {
                    companion: this.companion,
                    chatId: this.chatId,
                };
                this.setStepFromChat({ step: 0, fromChat: fromChat });
            } catch (error) {
                console.log(error);
            }
        },
        handleIncomingStory() {
            this.setChatChannel();
            this.$nextTick(() => {
                this.scrollToLastMessage = true;
            });
        },
        handleReloadChat() {
            this.setChatChannel();
            this.toLastMessage();
        },
        async setChatChannel() {
            return await new Promise(async (resolve) => {
                let {
                    chat_id,
                    interlocutor_id,
                    messages,
                    storyExist,
                    userImg,
                    isBroadcastingChannel,
                    isChatOwner,
                    pinned_message
                } = await this.setChat(
                    this.$route.params.chat_id
                        ? { chat_id: this.$route.params.chat_id }
                        : {
                              user_id:
                                  this.companionId || this.$route.params.id,
                          }
                );
                if (!chat_id) {
                    await this.$router.push({ name: "user.own.messages" });
                    return;
                }

                this.messages = messages;
                this.chatId = chat_id;
                this.interlocutorId = interlocutor_id;
                this.storyExist = storyExist;
                this.userImg = userImg;
                this.isBroadcastingChannel = isBroadcastingChannel;
                this.isChatOwner = isChatOwner;
                this.pinnedMessage = pinned_message;

                // Make event listener to work for all broadcast, DMs and broadcast channel owners
                
                if (this.chatId) {
                    this.$echo.connector.connect();
                    this.chatChannel = this.$echo.private(
                        `App.Models.Chat.${this.chatId}`
                    );
                    this.chatChannel
                        .listenForWhisper("TypingMessageEvent", () => {
                            this.handleShowTypingMessage();
                        })
                        .listen("IncomingMessageEvent", (response) => {
                            // This console is for test purpose(If listener works on staging or not)
                            console.log('event listened');
                            if (typeof response.message !== "undefined" && response.message.user_id !== this.$store.state.user.user.data.id) {
                                if (response.message.story_id) {
                                    this.handleIncomingStory();
                                } else {
                                    this.handleIncomingMessage(
                                        response.message
                                    );
                                }
                            }
                            this.toLastMessage();
                        });
                }
                this.toLastMessage();
                resolve();
            });
        },
        toLastMessage() {
            this.$nextTick(() => {
                this.scrollToLastMessage = true;
            });
        },

        async startRecordingAudio() {
            this.isAudioRecording = true;
            this.$core.storyCore.startRecordingAudio();
        },

        async stopRecordingAudio() {
            this.isAudioRecording = false;
            const audioBlob = await this.$core.storyCore.stopRecordingAudio();
            if (this.isBroadcastingChannel && this.isChatOwner) {
                let chatId = this.$route.params.chat_id;
                await this.uploadAudioMessageToBroadcast({ audioBlob, chatId: chatId })
                .then((res) => {
                    if (typeof res.data !== "undefined") {
                        this.setMessages(res.data);
                        this.messageModel = "";
    
                        if (!this.chatChannel) {
                            this.setChatChannel();
                        }
                        this.toLastMessage();

                        const broadcastAudioMessageInfo = {
                            message_type: 'Audio',
                            channel: 'Broadcast',
                            ownBroadcast: true,
                        };
                        this.$mixpanel.click('Send Audio', 'Chat Screen', {
                            message_info: broadcastAudioMessageInfo
                        });
                    }
                });
            } else {
                await this.checkPermissionToSendMessages();
                await this.uploadAudioMessage({ audioBlob, receiverId: this.interlocutorId })
                .then((res) => {
                    if (typeof res.data !== "undefined") {
                        this.setMessages(res.data);
                        this.messageModel = "";
    
                        if (!this.chatChannel) {
                            this.setChatChannel();
                        }
                        this.toLastMessage();

                        const chatAudioMessageInfo = {
                            message_type: 'Audio',
                            channel: 'Direct Message',
                            to: this.companion.name
                        };
                        this.$mixpanel.click('Send Audio', 'Chat Screen', {
                            message_info: chatAudioMessageInfo
                        });
                    }
                });
            }
        },

        async cancelRecordingAudio() {
            this.isAudioRecording = false;
            this.isRecordingDeleted = true;
            await this.$core.storyCore.cancelRecordingAudio()
            .then(() => {
                setTimeout(() => {
                    this.isRecordingDeleted = false;
                }, 1000);
            });
        },

        async MessagePinned(message_id) {
            await this.pinMessage({
                message_id: message_id,
                chat_id: this.chatId,
            })
            .then(() => {
                this.pinnedMessage = this.messages.find(msg => msg.id === message_id);
            });
        },

        async messageUnpinned(){
            await this.removePinMessage({
                chat_id: this.chatId,
            })
            .then(() => {
                this.pinnedMessage = null;
            });
        },

        ...mapActions([
            "setChat",
            "setOtherUser",
            "sendMessage",
            "sendTipMessage",
            "sendTipMessageToBroadcast",
            "subscribe",
            "buyDirectMessage",
            "readMessages",
            "uploadAudioMessage",
            "uploadAudioMessageToBroadcast",
            "pinMessage",
            "removePinMessage"
        ]),
        ...mapMutations(["setStepFromChat"]),
    },
    computed: {
        subscribePrice() {
            return this.companion.subscribePrice;
        },
        directPrice() {
            return this.companion.directMessagePrice;
        },
        ...mapState({
            // profileImage: state => state.otherUser.user.profileImage,
            user: (state) => state.otherUser.user,
            verified: (state) => state.otherUser.user.verified,
            step: (state) => state.story.isShowCameraScreen.step,
            currentUser: (state) => state.user.user.data,
        }),
        canSendMessages() {
            return this.user.canSendMessages;
        },
        pinnedMessageDisplay() {
            if (this.pinnedMessage.type == TYPE_STORY) {
                return this.$t("messages.type_story");
            } else if(this.pinnedMessage.type == TYPE_AUDIO) {
                return this.$t("messages.type_audio");
            }
            return this.pinnedMessage.message.length > 10 ? this.pinnedMessage.message.substring(0, 10) + '...' : this.pinnedMessage.message;
        }
    },
    async mounted() {
        this.$mixpanel.identifyUser(this.currentUser.email);
        await this.setChatChannel();

        this.setOtherUser(this.interlocutorId).then((companion) => {
            if (
                !!companion.err_key &&
                companion.err_key === ERROR_KEYS.NOT_FOUND
            ) {
                this.$router.push({ name: "404" });
            } else {
                this.companion = companion;
            }
            this.$mixpanel.track('chat_opened', {
                chat_with_user: this.companion.email,
            });
        });

        this.$nextTick(() => {
            this.scrollToLastMessage = true;
        });
    },
};
</script>

<style scoped lang="scss">
@import "@/sass/modules/_variables";

.bottom-control {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    height: 74px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    background: black;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
        border: none;
        background: transparent;
        min-width: 40px;
        height: 40px;
        padding: 10px;
    }
}

.show-pinned-chat-message { width: 100%; position: fixed; top: 0px; left: 0px; display: flex; flex-direction: column; background-color: #313131; border-radius: 0px; padding: 5px 10px; z-index: 99999; }
.show-pinned-chat-message span:nth-child(1) { font-size: 14px; font-weight: 600; }
.show-pinned-chat-message span:nth-child(2) { font-size: 12px; }
.show-pinned-chat-message button { position: absolute; top:50%; right: 15px; transform: translateY(-50%); }
</style>
